/* eslint-disable prettier/prettier */
import styled from 'styled-components';
import { shouldNotForwardPropsWithKeys } from 'utils/helpers/shouldNotForwardPropsWithKeys';

interface SvgProps {
  height: string;
  width: string;
  inactive?: boolean;
  state?: string;
  fillColor?: string;
}

export const Svg = styled.svg.withConfig({
  shouldForwardProp: shouldNotForwardPropsWithKeys<SvgProps>([
    'inactive',
    'state',
  ]),
})<SvgProps>`
  border-radius: 2px;
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  cursor: pointer;

  ${({
    theme: {
      colors: { grey, stateColors },
    },
    state,
    inactive,
    fillColor,
  }) => {
    {
      if (inactive) {
        return `
				path {
								fill: ${fillColor || grey[200]};
				}`;
      }

      if (state && stateColors[state]) {
        return `
				path {
						fill: ${stateColors[state].main};
					}
					&:hover {
						background-color: ${stateColors[state].lighter};
						path {
							fill: ${stateColors[state].light};
						}
					}

					&:active {
						background-color: ${stateColors[state].lighter};
						path {
							fill: ${stateColors[state].dark};
						}
				}`;
      }

      if (state === 'remove') {
        return `
		  path {
			  fill: ${grey[700]};
		  }
		  &:hover {
			  background-color: ${grey[300]};
			  path {
				  fill: ${grey[700]};
			  }
		  }
		  &:active {
			  background-color: ${grey[300]};
			  path {
				  fill: ${grey[700]};
			  }
		  }`;
      }

      if (state === 'removeAdminList') {
        // this the color we need for the property list fields on the property overview page of the Admin portal
        return `
					path {
						fill: ${grey[600]}
					}
				`;
      }

      if (state === 'advisorView') {
        return `
					path {
						fill: ${grey[800]}
					}
				`;
      }

      if (state === 'survey') {
        return `
					path {
						fill: ${grey[500]}
					}
				`;
      }

      return `
				path {
					fill: ${stateColors['danger'].main};
				}
				&:hover {
					background-color: ${stateColors['danger'].lighter};
					path {
						fill: ${stateColors['danger'].light};
					}
				}
				&:active {
					background-color: ${stateColors['danger'].lighter};
					path {
						fill: ${stateColors['danger'].dark};
					}
				}`;
    }
  }}
`;
