// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { StylesConfig } from 'react-select';
import theme from 'stories/theme';
import styled, { css, CSSProperties, DefaultTheme } from 'styled-components';

interface SelectContainerProps {
  noMargin?: boolean;
  marginBottom?: number;
  size?: 'small' | 'medium';
  bg?: string;
  noDefaultColor?: boolean;
  horizontalLabel?: boolean;
  error: boolean;
}

const getStylesByVariant = ({
  variant,
  state,
}: {
  variant?: number;
  state?: string;
}) => {
  switch (variant) {
    case 1: {
      return {
        backgroundColor: theme.colors.grey[600],
        backgroundColorHover: theme.colors.grey[500],
        iconColor: theme.colors.grey[400],
        iconColorHover: theme.colors.grey[300],
        border: 'none',
      };
    }
    case 2: {
      return {
        backgroundColor: 'transparent',
        backgroundColorHover: 'transparent',
        iconColor: theme.colors.grey[500],
        border: 'none',
        fontSize: theme.fonts.size.sm1,
      };
    }
    default: {
      return {
        backgroundColor: theme.colors.grey[0],
        border: `1px solid ${
          state && theme.colors.stateColors[state]
            ? theme.colors.stateColors[state].main
            : 'rgba(218, 218, 218, 0.7)'
        }`,
        iconColor: theme.colors.primary.main,
      };
    }
  }
};

export const Label = styled.label<{ isRequired: boolean; align?: string }>`
  display: block;
  margin: 0 0 0.5rem 0;
  font-size: 0.93em;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  line-height: 1.33;
  text-align: ${({ align }) => align || 'center'};

  ${({ isRequired, theme }) => {
    if (isRequired)
      return `::after {
        content: ' *';
        color: ${theme.colors.stateColors['danger'].darker};
    }`;
  }};
  color: ${({ theme }) => theme.colors.grey[800]};
`;

export const SelectContainer = styled.div<SelectContainerProps>`
  width: 100%;
  margin-bottom: ${({ noMargin, marginBottom, theme }) =>
    marginBottom
      ? theme.spacing[marginBottom]
      : !noMargin && theme.spacing[10]};
  div {
    div:first-child {
      ${({ bg, theme }) =>
        bg && `background-color: ${bg || theme.colors.grey[100]}`}
    }
  }

  [class$='control'] {
    cursor: pointer;
    ${({ size }) => {
      switch (size) {
        case 'small':
          return 'padding: 0rem 0;';
        default:
          return 'padding: .719rem 0;';
      }
    }}
  }

  position: relative;

  p {
    color: ${({ theme, error }) =>
      error ? theme.colors.stateColors.danger.main : theme.colors.grey[800]};
    font-size: 0.87em;
    max-width: 100%;
    bottom: -3rem;
    position: absolute;
  }

  ${({ noDefaultColor }) =>
    !noDefaultColor &&
    `
		.select____option:first-child {
			color: #aaaaaa !important;
		}
	`}

  ${({ horizontalLabel }) =>
    horizontalLabel &&
    css`
      display: flex;
      align-items: center;

      label {
        margin-right: 1rem;
      }
    `}
`;

export const ErrorLabel = styled.p`
  width: 100%;
  text-align: right;
`;

export const CustomOptionContainer = styled.div`
  padding: 0.5rem;
`;

export const CustomOptionStyle = styled.div`
  padding: 0.5rem;
`;

export const colourStyles = (
  theme?: DefaultTheme | undefined,
  state?: string,
  variant?: number,
  width?: string,
  singleValueCustomColor?: string
): StylesConfig<any, any> => {
  const styles = getStylesByVariant({ variant });
  return {
    control: (base: CSSProperties) => ({
      ...base,
      backgroundColor: styles.backgroundColor,
      ':hover': {
        backgroundColor: styles.backgroundColorHover,
      },
      fontFamily: theme && theme.fonts.secondary,
      fontSize: styles.fontSize || '1em',
      boxShadow: 'none',
      padding: '.688rem 0rem',
      border: styles.border,
      ...(width && { width }),
    }),
    option: (
      base: CSSProperties,
      {
        isDisabled,
        isFocused,
        data,
      }: {
        isDisabled: boolean;
        isFocused: boolean;
        data: { isHighlighted?: boolean };
      }
    ) => ({
      ...base,
      backgroundColor:
        isFocused || data?.isHighlighted
          ? theme && theme.colors.primary.lighter
          : theme && theme.colors.grey[0],
      color: theme && theme.colors.grey[800],
      fontFamily: theme && theme.fonts.secondary,
      cursor: isDisabled ? 'not-allowed' : 'default',
      opacity: isDisabled ? 0.5 : 1,
      ':active': {
        backgroundColor: theme && theme.colors.primary.lighter,
      },
      ...(width && { width }),
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      fontVariantNumeric: 'tabular-nums',
    }),
    menu: (base: CSSProperties) => ({
      ...base,
      margin: 0,
      borderRadius: '0px 0px 2px 2px',
      padding: 0,
      boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.5)',
      ':active': {
        boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.5)',
      },
      ':focus': {
        boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.5)',
      },
      ':hover': {
        boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.5)',
        ':active': {
          boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.5)',
        },
        ':focus': {
          boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.5)',
        },
        ':hover': {
          boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.5)',
        },
      },
      ...(width && { width }),
    }),
    noOptionsMessage: (base: CSSProperties) => ({
      ...base,
      fontFamily: theme && theme.fonts.secondary,
      color: theme && theme.colors.grey[800],
    }),
    placeholder: (base: CSSProperties) => ({
      ...base,
      fontVariantNumeric: 'tabular-nums',
    }),
    singleValue: (
      base: CSSProperties,
      { data }: { data: { label: string } }
    ) => ({
      ...base,
      color: singleValueCustomColor
        ? singleValueCustomColor
        : state && theme && theme.colors.stateColors[state]
        ? theme && theme.colors.stateColors[state].main
        : data.label !== ''
        ? theme && theme.colors.grey[800]
        : theme && theme.colors.grey[300],
      ':hover': {
        color: singleValueCustomColor
          ? singleValueCustomColor
          : state && theme && theme.colors.stateColors[state]
          ? theme && theme.colors.stateColors[state].main
          : data.label !== ''
          ? theme && theme.colors.grey[800]
          : theme && theme.colors.grey[300],
      },
      fontVariantNumeric: 'tabular-nums',
    }),
    dropdownIndicator: (base: CSSProperties) => ({
      ...base,
      color: styles.iconColor,
      ':active': {
        color: theme && theme.colors.primary.main,
      },
      ':hover': {
        color: styles.iconColorHover,
      },
    }),
    indicatorsContainer: (styles) => ({
      ...styles,
      gap: `1rem`,
    }),
  };
};
