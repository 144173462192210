import styled from 'styled-components';

interface WrapperProps {
  centerLogo?: boolean;
  mobileNavPadding?: boolean;
}

// 2025 Advisor View Cleanup
// interface AdminLogoOptions {
//   isAdvisorView: boolean | '' | undefined | null;
//   currentInvestorLength: number | undefined;
// }

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  justify-content: ${({ centerLogo }) =>
    centerLogo ? 'center' : 'space-between'};
  padding: ${({ mobileNavPadding }) =>
    mobileNavPadding ? '0' : '0.695rem 1.25rem'};
  background: none;
`;

export const NavbarContainer = styled.div`
  display: flex;
  align-items: center;
  height: 3.95rem;
`;

// ----------- sidebar content style start

interface OptionProps {
  color?: string;
}

export const Option = styled.div<OptionProps>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 1.375rem;
  background-color: ${({ theme }) => theme.colors.grey[0]};
  font-family: ${({ theme }) => theme.fonts.secondary};
  min-height: 3.75rem;
  width: 100%;
  cursor: pointer;
`;

export const Identifications = styled.div`
  padding: ${({ theme }) => theme.spacing[4]};
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

// ----------- sidebar content style end

// ----------- Admin content styles start
export const WelcomeMessage = styled.p`
  color: ${({ theme }) => theme.colors.grey[0]};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  font-size: 1em;
  line-height: 19px;
  text-align: right;
  margin-right: 1rem;
`;

export const AdminAvatarContainer = styled.div`
  justify-self: flex-end;
  display: flex;
  align-items: center;
`;

export const UserIconWrapper = styled.div`
  cursor: pointer;
`;

// 2025 Advisor View Cleanup
// export const AdvisorWrapper = styled.div`
//   display: flex;
//   align-items: center;
// `;

// export const ActiveAdvisorWrapper = styled.div`
//   display: flex;
//   align-items: center;
//   margin-right: 1rem;
//   background-color: ${({ theme }) => theme.colors.grey[200]};
//   border-radius: 100px;
//   padding: 4px 16px 4px 16px;
//   border: 1px solid ${({ theme }) => theme.colors.grey[300]};
//   font-size: 15px;
// `;

// export const ActiveAdvisorName = styled.text`
//   font-style: ${({ theme }) => theme.fonts.weight.normal};
//   font-size: ${({ theme }) => theme.fonts.size.base};
//   margin-right: 0.5rem;
// `;

// export const AdvisorViewIconWrapper = styled.div`
//   cursor: pointer;
// `;

// export const AdminLogoWrapper = styled.div<AdminLogoOptions>`
//   padding-left: ${({ isAdvisorView, currentInvestorLength }) =>
//     isAdvisorView ? currentInvestorLength?.toString() + 'px' : '0px'};
// `;

export const ClientsWrapper = styled.div`
  margin: 0 ${({ theme }) => theme.spacing[4]};
  [class*='SelectContainer'] {
    margin-bottom: 0;
    z-index: 99;
  }
`;

export const LoaderWrapper = styled.div`
  min-height: ${({ theme }) => theme.spacing[12]};
`;

export const AppVersionAndCopyrightWrapper = styled.div`
  position: absolute;
  bottom: ${({ theme }) => theme.spacing[4]};
  padding: 0 ${({ theme }) => theme.spacing[4]};
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: ${({ theme }) => theme.spacing[2]};
`;

export const AppVersionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing[3]};
`;

export const AppVersion = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.grey[700]};
`;

export const CopyrightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[1]};
  a {
    color: inherit;
    text-decoration: underline;
  }
  p {
    font-size: 12px;
    padding: 0;
    margin: 0;
  }
`;

export const LogoWrapper = styled.div``;
