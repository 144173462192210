import { SvgProps } from 'interfaces';
import styled from 'styled-components';
import { getVariantSvg } from 'ui/theme/helpers';
import { VariantTypes } from 'ui/uiTypes';
import { shouldNotForwardPropsWithKeys } from 'utils/helpers/shouldNotForwardPropsWithKeys';

export const Path = styled.path.withConfig({
  shouldForwardProp: shouldNotForwardPropsWithKeys(['variant']),
})<{ variant?: VariantTypes }>`
  ${getVariantSvg()}
`;

export const Svg = styled.svg<SvgProps>`
  height: ${({ height }) => `${height}px`};
  width: ${({ width }) => `${width}px`};
  cursor: pointer;
`;
